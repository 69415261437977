/* Inspired by https://github.com/ankurk91/vue-loading-overlay */

<template lang="pug">
  transition(:name='transition')
    .wc-loader.wc-loader-overlay.is-active(
      v-show='isActive'
      tabindex='0'
      :class='loaderClass'
      :style='loaderStyle'
      :aria-busy='isActive'
      aria-label='Loading')
      .wc-loader-background(
        @click.stop.prevent='clicked'
        :class="{ 'rounded-wc': backgroundRounded }"
        :style='{ background: this.backgroundColor, opacity: this.opacity, borderRadius: this.backgroundRadius }')
      .wc-loader-icon
        slot(name='before')
        slot(name='default')
          component(
            :is='loader'
            :color='color'
            :colorDuotone='colorDuotone'
            :size='size')
        slot(name='after')
</template>

<script>
import WcLoadersCube from '@components/shared/loaders/WcLoadersCube'

export default {
  name: 'wc-loader',
  components: {
    WcLoadersCube,
  },
  props: {
    active: Boolean,
    transition: {
      type: String,
      default: 'fade',
    },
    isFullPage: {
      type: Boolean,
      default: null,
    },
    canCancelEsc: {
      type: Boolean,
      default: false,
    },
    canCancelClick: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    loader: {
      type: String,
      default: 'wc-loaders-cube',
    },
    color: String,
    colorDuotone: String,
    backgroundColor: String,
    backgroundRadius: String,
    backgroundRounded: Boolean,
    minHeight: {
      type: [Boolean, String],
      default: '20rem',
    },
    opacity: Number,
    size: Number,
  },
  computed: {
    loaderClass() {
      return (this.isFullPage === null ? this.$store.getters['ui/isMediumDevice'] : this.isFullPage)
        ? 'is-full-page'
        : ''
    },
    loaderStyle() {
      let style = {}
      if (this.minHeight) style['min-height'] = this.minHeight
      return style
    },
  },
  methods: {
    cancel() {
      if (!this.isActive) return
      this.$emit('update:active', false)
      this.onCancel.apply(null, arguments)
    },
    keyPress(event) {
      if (
        this.canCancelEsc &&
        (('key' in event && (event.key === 'Escape' || event.key === 'Esc')) ||
          event.keyCode === 27)
      ) {
        this.cancel()
      }
    },
    clicked() {
      if (this.canCancelClick) {
        this.cancel()
      }
    },
  },
  watch: {
    active(value) {
      this.isActive = value
    },
  },
  data() {
    return {
      isActive: this.active,
    }
  },
  mounted() {
    document.addEventListener('keyup', this.keyPress)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyPress)
  },
}
</script>

<style lang="scss">
.wc-loader {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 10;

  &.is-active {
    display: flex;
  }

  &.is-full-page {
    z-index: 999;
    position: fixed;
    .wc-loader-background {
      border-radius: 0 !important;
    }
  }

  .wc-loader-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    opacity: 0.5;
  }

  .wc-loader-icon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.wc-loader-parent {
  position: relative;
}
</style>
